<template>
  <article class="pu-details-kpis wx-tile-panel">
    <h3 v-if="!isXs" class="mb-6 text-center">{{ $t("overview.details.kpis.title") }}</h3>

    <ul>
      <li
        v-for="(kpi, index) in productionUnitKpis"
        :key="'detailsKpis-' + index"
        class="kpi pa-3"
        :class="{ 'mb-4': index < productionUnitKpis.length - 1 }"
      >
        <div class="kpi-title-and-target mr-2">
          <span class="mr-1">{{ i18nKpiName(kpi.name) }}</span>
          <span v-if="kpi.target !== null && kpi.target !== undefined" class="wx-subtle-text wx-typo-sm">
            ({{ $t("overview.details.kpis.target") }} {{ kpi.target }}{{ isPercentTarget(kpi.name) ? "%" : "" }})
          </span>
        </div>
        <span class="kpi-value wx-typo-h3 mr-2">{{ formatKpi(kpi.valueString) }}</span>
        <span class="kpi-indicator" :class="kpiStatus(kpi)"></span>
      </li>
    </ul>
  </article>
</template>

<script>
import { mapGetters } from "vuex";
import Helpers, { dash } from "@/helpers";
import Tiles from "@/components/Tiles";
import PackageFeatures from "@/components/PackageFeatures";
import { getUnitType, validProductUnits } from "@/components/user/UserPreferencesService";
import { convertKilogramTo, convertLiterTo, convertMeterTo } from "@/UnitUtils";
import Accessibility from "@/components/Accessibility";

export default {
  name: "PUDetailsKpis",
  computed: {
    ...mapGetters("overview", [
      "activeProductionUnitIdForDetails",
      "productionUnitsStateAndKpis",
      "productionUnitsSpeed",
      "activeKpiConfig",
    ]),
    ...mapGetters("packages", ["puHasRequiredFeature"]),
    isActiveKpiConfigProductUnitCount() {
      return !this.activeKpiConfig?.product_unit || validProductUnits.indexOf(this.activeKpiConfig.product_unit) >= 0;
    },
    productionUnitKpis() {
      const puId = this.activeProductionUnitIdForDetails;
      const puStateAndKpis = this.productionUnitsStateAndKpis.find((p) => p.puId === puId);
      const puSpeed = this.productionUnitsSpeed.find((p) => p.production_unit_id === puId);
      const puSpeedInUnitsPerHour = this.isActiveKpiConfigProductUnitCount ? puSpeed?.product_speed : this.getConvertedValue(puSpeed?.weight_length_volume_speed, puStateAndKpis.wlvRateUnit) ?? null;
      const puSpeedInUnitsPerMinute = puSpeedInUnitsPerHour ? puSpeedInUnitsPerHour / 60.0 : null;

      if (puStateAndKpis) {
        let ratePerHourTargetOrDash = Helpers.getRoundedValueOrDash(
          this.isActiveKpiConfigProductUnitCount ? puStateAndKpis.ratePerHourTargetValue : this.getConvertedValue(puStateAndKpis.wlvRatePerHourTargetValue, puStateAndKpis.wlvRateUnit),
          this.puHasRequiredFeature(puId, Tiles.currentProductThroughputPerMinute.requiredFeature),
          2,
        );
        let ratePerMinuteTargetOrDash = Helpers.getRoundedValueOrDash(
          this.isActiveKpiConfigProductUnitCount ? puStateAndKpis.ratePerMinuteTargetValue : this.getConvertedValue(puStateAndKpis.wlvRatePerMinuteTargetValue, puStateAndKpis.wlvRateUnit),
          this.puHasRequiredFeature(puId, Tiles.currentProductThroughputPerMinute.requiredFeature),
          2,
        );
        // Rows in the key Performance Indications when clicking on a pu
        return [
          {
            name: "oee",
            valueString: Helpers.getRoundedPercentageOrDash(
              puStateAndKpis.oeeValue,
              this.puHasRequiredFeature(puId, Tiles.oee.requiredFeature),
            ),
            value: puStateAndKpis.oeeValue,
            target: puStateAndKpis.oeeTarget,
            authorized: this.puHasRequiredFeature(puId, Tiles.oee.requiredFeature),
          },
          {
            name: "availability",
            valueString: Helpers.getRoundedPercentageOrDash(
              puStateAndKpis.availabilityValue,
              this.puHasRequiredFeature(puId, Tiles.availability.requiredFeature),
            ),
            value: puStateAndKpis.availabilityValue,
            target: puStateAndKpis.availabilityTarget,
            authorized: this.puHasRequiredFeature(puId, Tiles.availability.requiredFeature),
          },
          {
            name: "performance",
            valueString: Helpers.getRoundedPercentageOrDash(
              puStateAndKpis.performanceValue,
              this.puHasRequiredFeature(puId, Tiles.performance.requiredFeature),
            ),
            value: puStateAndKpis.performanceValue,
            target: puStateAndKpis.performanceTarget,
            authorized: this.puHasRequiredFeature(puId, Tiles.performance.requiredFeature),
          },
          {
            name: "quality",
            valueString: Helpers.getRoundedPercentageOrDash(puStateAndKpis.qualityValue),
            value: puStateAndKpis.qualityValue,
            target: this.puHasRequiredFeature(puId, PackageFeatures.reject) ? puStateAndKpis.qualityTarget : null,
            authorized: this.puHasRequiredFeature(puId, Tiles.quality.requiredFeature),
          },
          {
            name: "ooe",
            valueString: Helpers.getRoundedPercentageOrDash(
              puStateAndKpis.ooeValue,
              this.puHasRequiredFeature(puId, Tiles.ooe.requiredFeature),
            ),
            value: puStateAndKpis.ooeValue,
            target: puStateAndKpis.ooeTarget,
            authorized: this.puHasRequiredFeature(puId, Tiles.ooe.requiredFeature),
          },
          {
            name: "allProductQuantity",
            valueString: Helpers.getRoundedValueOrDash(
              puStateAndKpis.allProductQuantityValue,
              this.puHasRequiredFeature(puId, Tiles.allProductsQuantity.requiredFeature),
            ),
            value: puStateAndKpis.allProductQuantityValue,
            target: null,
            authorized: this.puHasRequiredFeature(puId, Tiles.allProductsQuantity.requiredFeature),
          },
          {
            name: "currentProductQuantity",
            valueString: Helpers.getRoundedValueOrDash(
              puStateAndKpis.currentProductQuantityValue,
              this.puHasRequiredFeature(puId, Tiles.currentProductQuantity.requiredFeature),
            ),
            value: puStateAndKpis.currentProductQuantityValue,
            target: null,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductQuantity.requiredFeature),
          },
          {
            name: "ratePerHour",
            valueString: Helpers.getRoundedValueOrDash(
              this.isActiveKpiConfigProductUnitCount ? puStateAndKpis.ratePerHourValue : this.getConvertedValue(puStateAndKpis.wlvRatePerHourValue, puStateAndKpis.wlvRateUnit),
              this.puHasRequiredFeature(puId, Tiles.currentProductThroughput.requiredFeature),
            ),
            value: this.isActiveKpiConfigProductUnitCount ? puStateAndKpis.ratePerHourValue : this.getConvertedValue(puStateAndKpis.wlvRatePerHourValue, puStateAndKpis.wlvRateUnit),
            target: ratePerHourTargetOrDash,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductThroughput.requiredFeature),
          },
          {
            name: "ratePerMinute",
            valueString: Helpers.getRoundedValueOrDash(
              this.isActiveKpiConfigProductUnitCount ? puStateAndKpis.ratePerMinuteValue : this.getConvertedValue(puStateAndKpis.wlvRatePerMinuteValue, puStateAndKpis.wlvRateUnit),
              this.puHasRequiredFeature(puId, Tiles.currentProductThroughputPerMinute.requiredFeature),
            ),
            value: this.isActiveKpiConfigProductUnitCount ? puStateAndKpis.ratePerMinuteValue : this.getConvertedValue(puStateAndKpis.wlvRatePerMinuteValue, puStateAndKpis.wlvRateUnit),
            target: ratePerMinuteTargetOrDash,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductThroughputPerMinute.requiredFeature),
          },
          {
            name: "speed5Minute",
            valueString: Helpers.getRoundedValueOrDash(
              puSpeedInUnitsPerHour,
              this.puHasRequiredFeature(puId, Tiles.currentProductSpeed5m.requiredFeature),
            ),
            value: puSpeedInUnitsPerHour,
            target: ratePerHourTargetOrDash,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductSpeed5m.requiredFeature),
          },
          {
            name: "speed5MinutePerMinute",
            valueString: Helpers.getRoundedValueOrDash(
              puSpeedInUnitsPerMinute,
              this.puHasRequiredFeature(puId, Tiles.currentProductSpeed5m.requiredFeature),
            ),
            value: puSpeedInUnitsPerMinute,
            target: ratePerMinuteTargetOrDash,
            authorized: this.puHasRequiredFeature(puId, Tiles.currentProductSpeed5m.requiredFeature),
          },
        ];
      } else {
        return [];
      }
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    successCssClass() {
      return Accessibility.addPalette("good");
    },
    warningCssClass() {
      return Accessibility.addPalette("average");
    },
    errorCssClass() {
      return Accessibility.addPalette("bad");
    },
    unknownStatusCssClass() {
      return Accessibility.addPalette("unknown");
    },
  },
  methods: {
    getConvertedValue(value, kpiUnit) {
      const unit = this.activeKpiConfig?.product_unit;
      if (!unit) return value;
      const unitType = getUnitType(unit);
      switch (unitType) {
        case "product_unit": {
          return value;
        }
        case "weight_unit": {
          // value was asked to be shown in weight but the current wlv value is not in weight
          if (kpiUnit !== "kg") return null;
          return convertKilogramTo(value, unit);
        }
        case "length_unit": {
          // value was asked to be shown in weight but the current wlv value is not in weight
          if (kpiUnit !== "m") return null;
          return convertMeterTo(value, unit);
        }
        case "volume_unit": {
          // value was asked to be shown in weight but the current wlv value is not in weight
          if (kpiUnit !== "l") return null;
          return convertLiterTo(value, unit);
        }
      }
    },
    isPercentTarget(kpiName) {
      let percentTargets = ["oee", "availability", "performance", "quality", "ooe"];
      return percentTargets.includes(kpiName);
    },
    formatKpi(kpiValue) {
      return kpiValue !== null ? kpiValue : dash;
    },
    kpiStatus(kpi) {
      if (
        kpi.target === null ||
        kpi.target === undefined ||
        kpi.value === null ||
        !kpi.authorized ||
        isNaN(kpi.value) ||
        isNaN(kpi.target)
      ) {
        return this.unknownStatusCssClass;
      }
      if ((kpi.name === "speed5Minute" || kpi.name === "speed5MinutePerMinute") && kpi.value === 0) {
        return this.unknownStatusCssClass;
      }
      const value = kpi.value - kpi.target;
      let status;
      if (value >= 0) {
        status = this.successCssClass;
      } else if (value >= -5) {
        status = this.warningCssClass;
      } else {
        status = this.errorCssClass;
      }
      return status;
    },
    i18nKpiName(kpiName) {
      switch (kpiName) {
        case "oee":
          return this.$t("overview.details.kpis.oee");
        case "availability":
          return this.$t("overview.details.kpis.availability");
        case "performance":
          return this.$t("overview.details.kpis.performance");
        case "quality":
          return this.$t("overview.details.kpis.quality");
        case "allProductQuantity":
          return this.$t("overview.details.kpis.allProductQuantity");
        case "currentProductQuantity":
          return this.$t("overview.details.kpis.currentProductQuantity");
        case "ratePerHour":
          return this.$t("overview.details.kpis.ratePerHour");
        case "ratePerMinute":
          return this.$t("overview.details.kpis.ratePerMinute");
        case "speed5Minute":
          return this.$t("overview.details.kpis.speed5Minute");
        case "speed5MinutePerMinute":
          return this.$t("overview.details.kpis.speed5MinutePerMinute");
        case "productionRunQuantity":
          return this.$t("overview.details.kpis.productionRunQuantity");
        case "ooe":
          return this.$t("overview.details.kpis.ooe");
        default:
          console.warn("Missing case for KPI " + kpiName);
          return kpiName;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wx-tile-panel {
  .kpi {
    background-color: var(--color-element-layer2);
    border-radius: var(--border-radius-sm);
  }
}

.kpi {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid var(--color-border-theme);
  border-radius: var(--border-radius-lg);
}

.kpi-title-and-target {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex-shrink: 1;
}

.kpi-value {
  margin-left: auto;
  flex-shrink: 0;
}

.kpi-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid transparent;
  flex-shrink: 0;

  &:before {
    content: "";
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 50%;
  }

  // --------------------------------------------------------------------------------------------------
  // Above the target
  // --------------------------------------------------------------------------------------------------
  &.good {
    border-color: var(--color-success);
    &:before {
      background: var(--color-success-alpha);
    }
  }
  &.good-accessible {
    border-color: var(--color-successAccessible);
    &:before {
      background: var(--color-successAccessible-alpha);
    }
  }

  // --------------------------------------------------------------------------------------------------
  // Under the target, but close
  // --------------------------------------------------------------------------------------------------
  &.average {
    border-color: var(--color-warning);
    &:before {
      background: var(--color-warning-alpha);
    }
  }
  &.average-accessible {
    border-color: var(--color-warningAccessible);
    &:before {
      background: var(--color-warningAccessible-alpha);
    }
  }

  // --------------------------------------------------------------------------------------------------
  // Under the target
  // --------------------------------------------------------------------------------------------------
  &.bad {
    border-color: var(--color-error);
    &:before {
      background: var(--color-error-alpha);
    }
  }
  &.bad-accessible {
    border-color: var(--color-errorAccessible);
    &:before {
      background: var(--color-errorAccessible-alpha);
    }
  }

  // --------------------------------------------------------------------------------------------------
  // Unknown status
  // --------------------------------------------------------------------------------------------------
  &.unknown {
    border-color: var(--color-secondary);
    &:before {
      background: var(--color-secondary-alpha);
    }
  }
  &.unknown-accessible {
    border-color: var(--color-secondaryAccessible);
    &:before {
      background: var(--color-secondaryAccessible-alpha);
    }
  }
}
</style>
